



















































































































import Icon from '@/components/Icon.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const Auth = namespace('Auth')

@Component({
  components: { Icon, UserAvatar }
})
export default class MobileNavbar extends Vue {
  @Auth.State('user')
  private currentUser

  @Prop()
  chatMessages

  @Prop()
  logOut

  @Prop()
  showAdminBoard

  @Prop()
  cartCount

  @Prop()
  userInfo

  private burgerIconName = 'nav-open';
  private burgerOn = 'none';

  toggleMenu (): void {
    if (this.burgerOn === 'block') {
      this.burgerOn = 'none'
      this.burgerIconName = 'nav-open'
    } else {
      this.burgerOn = 'block'
      this.burgerIconName = 'nav-close'
    }
  }
}
