




































import { Component, Vue } from 'vue-property-decorator'
import AdminService from '@/services/AdminService'
import { namespace } from 'vuex-class'

const Auth = namespace('Auth')

@Component
export default class PortalSettingsManager extends Vue {
  @Auth.State('user')
  private currentUser;

  private allItems = [];

  private itemFields = [
    {
      key: 'key',
      label: 'nimetus',
      sortable: true,
      class: 'key'
    },
    {
      key: 'value',
      label: 'väärtus'
    },
    {
      key: 'description',
      label: 'kirjeldus'
    }
  ]

  mounted (): void {
    this.fetchProjectSettings()
  }

  private fetchProjectSettings (): void {
    AdminService.fetchPortalSettings().then(
      (response) => {
        this.allItems = response.data
      })
  }

  private updateSetting (item: any): void {
    const payload = {
      id: item.id,
      key: item.key,
      value: item.value,
      description: item.description,
      createdAt: item.createdAt,
      createdBy: item.createdBy,
      updatedBy: this.currentUser.firstName
    }
    this.$validator.validateAll().then((isValid) => {
      if (isValid) {
        AdminService.updateSetting(payload).then(() => {
          window.location.reload()
        })
      }
    })
  }
}

