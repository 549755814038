








import { Component, Vue } from 'vue-property-decorator'
import SideNav from '@/components/SideNav.vue'

@Component({
  components: { SideNav }
})
export default class UserBoard extends Vue {
}
