




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import BigOrangeButton from '@/components/BigOrangeButton.vue'
import OrderService from '@/services/OrderService'
import { SWAP_STATUS_NAMES } from '@/models/id-maps'
import { SwapInOrder } from '@/models/swap'

const Auth = namespace('Auth')

@Component({
  components: { BigOrangeButton }
})
export default class StatusInfoPanel extends Vue {
  @Auth.Action
  private updateBookedPoints: (points: number) => void

  @Auth.State('user')
  private currentUser!: any;

  @Prop()
  order

  @Prop()
  isSeller

  private updateOrder (order: any, statusId: number): void {
    this.executeUpdate(order, statusId).then(() => {
      if (statusId === 2) {
        const total = this.getTotal(order.swaps)
        this.updateBookedPoints(total)
      }
    }).finally(() => {
      window.location.reload()
    })
  }

  private async executeUpdate (order: any, statusId: number): Promise<void> {
    await OrderService.updateOrderStatus(order.id, statusId, this.currentUser.id).then(
      () => {
        this.order.swapStatusId = statusId
        this.order.swapStatus.name = SWAP_STATUS_NAMES[statusId]
      }
    )
  }

  private isWaitingSellerConfirmation (swapStatus: any): boolean {
    return swapStatus.name === 'pending'
  }

  private isWaitingBuyerConfirmation (swapStatus: any): boolean {
    return swapStatus.name === 'seller_confirmed'
  }

  private isCompleted (swapStatus: any): boolean {
    return swapStatus.name === 'completed'
  }

  private isCanceled (swapStatus: any): boolean {
    return swapStatus.name === 'cancelled'
  }

  private isWaitingFeedbackFromBuyer (order: any): boolean {
    return order.buyerReviewed
  }

  private isWaitingFeedbackFromSeller (order: any): boolean {
    return order.sellerReviewed
  }

  private canDecline (order: any): boolean {
    const state = order.swapStatusId === 1 && this.isSeller
    return state
  }

  private canCancel (order: any): boolean {
    const state = !this.canDecline(order) && order.swapStatusId !== 2 && order.swapStatusId !== 3
    return state
  }

  private getTotal (swaps: SwapInOrder[]): number {
    let total = 0
    swaps.forEach(swap => {
      total += swap.toy.price
    })
    return total
  }
}
