





















































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import et from 'vee-validate/dist/locale/et'
import { ERROR_CODE } from '@/models/error-map'
import { errors } from 'jose'

const Auth = namespace('Auth')

@Component({
  computed: {
    errors () {
      return errors
    }
  }
})
export default class Register extends Vue {
  private user = { email: '', firstName: '', lastName: '', password: '', referralCode: null };

  private submitted = false;
  private successful = false;
  private message = '';
  private loading = false;

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private register!: (data: any) => Promise<any>;

  mounted (): void {
    this.$validator.localize('et', et)
    if (this.isLoggedIn) {
      this.$router.push('/profile')
    }
    this.scrollToView()
  }

  private scrollToView (): void {
    const pageContainer = document.getElementById('register-container')
    pageContainer.scrollIntoView({ behavior: 'smooth' })
  }

  handleRegister (): void {
    this.message = ''
    this.submitted = true
    this.loading = true

    this.user.referralCode = this.$cookies.get('referral_code')

    this.$validator.validateAll().then((isValid) => {
      if (isValid) {
        this.register(this.user).then(
          (data) => {
            this.message = data.message
            this.successful = true
            this.loading = false
          },
          (error) => {
            this.message = ERROR_CODE[error]
            this.loading = false
          }
        )
      }
      if (!isValid) {
        this.successful = false
        this.loading = false
      }
    })
  }
}
