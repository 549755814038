





















import { Component, Prop, Vue } from 'vue-property-decorator'
import PhotoUtilities from '@/utilities/PhotoUtilities'
import { namespace } from 'vuex-class'

const Auth = namespace('Auth')

@Component
export default class ToyCard extends Vue {
  @Auth.State('user')
  private currentUser;

  @Prop() toy;

  private productPhoto = '';

  mounted (): void {
    this.setProductPhoto()
  }

  private setProductPhoto (): void {
    if (this.toy.toyPhotos === null || this.toy.toyPhotos === undefined || this.toy.toyPhotos.length < 1) {
      this.productPhoto = '../Leluelu_logo_v02.svg'
    } else {
      this.productPhoto = PhotoUtilities.getProductPhotoUrl(this.toy.toyPhotos[0].photoReference)
    }
  }
}
