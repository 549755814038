import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import AuthService from '@/services/AuthService'

const storedUser = localStorage.getItem('user')

@Module({ namespaced: true })
class User extends VuexModule {
  private status = storedUser ? { loggedIn: true } : { loggedIn: false };
  private user = storedUser ? JSON.parse(storedUser) : null;

  @Mutation
  public loginSuccess (user): void {
    this.status.loggedIn = true
    this.user = user
  }

  @Mutation
  public loginFailure (): void {
    this.status.loggedIn = false
    this.user = null
  }

  @Mutation
  public logout (): void {
    this.status.loggedIn = false
    this.user = null
  }

  @Mutation
  public registerSuccess (): void {
    this.status.loggedIn = false
  }

  @Mutation
  public registerFailure (): void {
    this.status.loggedIn = false
  }

  @Mutation
  public updateUserPoints (points): void {
    this.user.activePoints -= points
    this.user.bookedPoints += points
    localStorage.setItem('user', JSON.stringify(this.user))
  }

  @Mutation
  public updateUserBookedPoints (points): void {
    this.user.bookedPoints -= points
    localStorage.setItem('user', JSON.stringify(this.user))
  }

  @Action({ rawError: true })
  login (data: any): Promise<any> {
    return AuthService.login(data.email, data.password).then(
      user => {
        this.context.commit('loginSuccess', user)
        return Promise.resolve(user)
      },
      error => {
        this.context.commit('loginFailure')
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
        return Promise.reject(message)
      }
    )
  }

  @Action
  signOut (): void {
    AuthService.logout()
    this.context.commit('logout')
  }

  @Action
  isValidSession (): boolean {
    return AuthService.isValidSession()
  }

  @Action({ rawError: true })
  register (data: any): Promise<any> {
    return AuthService.register(data.email, data.firstName, data.lastName, data.password, data.referralCode).then(
      response => {
        this.context.commit('registerSuccess')
        return Promise.resolve(response.data)
      },
      error => {
        this.context.commit('registerFailure')
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
        return Promise.reject(message)
      }
    )
  }

  @Action({ rawError: true })
  emailVerify (data: any): Promise<any> {
    return AuthService.emailVerification(data.token, data.email).then(
      user => {
        this.context.commit('loginSuccess', user)
        return Promise.resolve(user)
      },
      error => {
        this.context.commit('loginFailure')
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
        return Promise.reject(message)
      }
    )
  }

  @Action({ rawError: true })
  agreeToTerms (): Promise<any> {
    return AuthService.agreeToTerms().then(
      response => {
        return Promise.resolve(response.data)
      },
      error => {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
        return Promise.reject(message)
      }
    )
  }

  @Action({ rawError: true })
  updateProfile (data): Promise<any> {
    return AuthService.updateProfile(data).then(
      response => {
        this.context.commit('registerSuccess')
        return Promise.resolve(response.data)
      },
      error => {
        this.context.commit('registerFailure')
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
        return Promise.reject(message)
      }
    )
  }

  @Action
  updatePoints (points: number) {
    this.context.commit('updateUserPoints', points)
  }

  @Action
  updateBookedPoints (points: number) {
    this.context.commit('updateUserBookedPoints', points)
  }

  @Action
  resetPassword (email: string): Promise<any> {
    return AuthService.passwordReset(email)
  }

  @Action({ rawError: true })
  confirmPasswordReset (data): Promise<any> {
    return AuthService.confirmPasswordReset(data.token, data.email, data.password).then(
      response => {
        return Promise.resolve()
      },
      error => {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
        return Promise.reject(message)
      }
    )
  }

  get isLoggedIn (): boolean {
    return this.status.loggedIn
  }
}

export default User
