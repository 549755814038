









import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import SideNav from '@/components/SideNav.vue'

const Auth = namespace('Auth')

@Component({
  components: { SideNav }
})
export default class AdminBoard extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  private navLinks = [{
    path: '/admin/users',
    label: 'kasutajad'
  }, {
    path: '/admin/toys',
    label: 'mänguasjad'
  }, {
    path: '/admin/swaps',
    label: 'vahetused'
  }, {
    path: '/admin/parameters',
    label: 'otsinguparameetrid'
  }, {
    path: '/admin/settings',
    label: 'portaali seaded'
  }, {
    path: '/admin/notifications',
    label: 'teavitused'
  }]

  mounted (): void {
    if (!this.canShowAdminBoard()) {
      this.$router.push('/home')
    }
  }

  private canShowAdminBoard (): boolean {
    if (this.currentUser && this.currentUser.roles) {
      return this.currentUser.roles.includes('Admin')
    }
    return false
  }
}
