var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contacts-nav"},[(_vm.isBusy)?_c('div',[_c('div',{staticClass:"d-flex justify-content-center mb-3 spinner"},[_c('b-spinner',{attrs:{"variant":"warning","type":"grow"}})],1)]):_vm._e(),(!_vm.isBusy)?_c('div',[(this.chatData.length === 0)?_c('div',[_c('span',{staticClass:"body-text-m"},[_vm._v("Teil pole veel vestlusi")])]):_vm._e(),_vm._l((this.chatData),function(chat){return _c('div',{key:("" + (chat.id)),staticClass:"no-bullets chat-entry"},[_c('div',{staticClass:"chat-contact"},[(_vm.currentUser.id === chat.data.fromApplicationUserId)?_c('div',[_c('a',{staticClass:"nav-link",attrs:{"href":("/chat/" + (chat.data.fromApplicationUserId))}},[_c('user-avatar',{attrs:{"big":true,"user":{
                  pictureReference: chat.data.toApplicationUser.profilePictureReference,
                  firstName: chat.data.toApplicationUser.firstName,
                  lastName: chat.data.toApplicationUser.lastName
                },"new-messages-count":chat.newMessages,"url":("" + (chat.data.toApplicationUserId))}})],1)]):_vm._e(),(_vm.currentUser.id !== chat.data.fromApplicationUserId)?_c('div',[_c('a',{staticClass:"nav-link",attrs:{"href":("/chat/" + (chat.data.fromApplicationUserId))}},[_c('user-avatar',{attrs:{"big":true,"user":{
                  pictureReference: chat.data.fromApplicationUser.profilePictureReference,
                  firstName: chat.data.fromApplicationUser.firstName,
                  lastName: chat.data.fromApplicationUser.lastName
                },"new-messages-count":chat.newMessages,"url":("" + (chat.data.fromApplicationUserId))}})],1)]):_vm._e()])])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }