



































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import et from 'vee-validate/dist/locale/et'
import { ERROR_CODE } from '@/models/error-map'

const Auth = namespace('Auth')

@Component
export default class PasswordReset extends Vue {
  private pwReset = { email: '', password: '', token: '' };

  private submitted = false;
  private successful = false;
  private message = '';
  private loading = false;

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private confirmPasswordReset!: (data: any) => Promise<any>;

  mounted (): void {
    // @ts-ignore
    this.pwReset.email = this.$route.query.email
    // @ts-ignore
    this.pwReset.token = this.$route.query.token
    this.$validator.localize('et', et)
    if (this.isLoggedIn) {
      this.$router.push('/profile')
    }
    this.scrollToView()
  }

  private scrollToView (): void {
    const pageContainer = document.getElementById('reset-pw-container')
    pageContainer.scrollIntoView({ behavior: 'smooth' })
  }

  handleNewPassword (): void {
    this.message = ''
    this.submitted = true
    this.loading = true

    this.$validator.validateAll().then((isValid) => {
      if (isValid) {
        this.confirmPasswordReset(this.pwReset).then(
          (response) => {
            this.successful = true
            this.loading = false
          },
          (error) => {
            this.successful = false
            this.loading = false
            this.message = ERROR_CODE[error]
          }
        )
      }
      if (!isValid) {
        this.successful = false
        this.loading = false
      }
    })
  }

  private encodeComponent (component: string) {
    return encodeURIComponent(component)
  }
}
