







import Icon from '@/components/Icon.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: { Icon }
})
export default class BigOrangeButton extends Vue {
  @Prop() action
  @Prop() toy
  @Prop() iconName
  @Prop() hoverIconName
  @Prop() label

  private disabled = false

  private clicked () {
    this.disabled = true
    this.$emit('clicked')
  }
}
