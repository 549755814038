










































































































import ChatLink from '@/components/ChatLink.vue'
import DeliveryInfoPanel from '@/components/DeliveryInfoPanel.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import StatusInfoPanel from '@/components/pages/orderPage/StatusInfoPanel.vue'
import DateUtilities from '@/utilities/DateUtilities'
import PhotoUtilities from '@/utilities/PhotoUtilities'
import Icon from '@/components/Icon.vue'
import OrderService from '@/services/OrderService'
import { SWAP_STATUS_NAMES } from '@/models/id-maps'
import { SwapInOrder } from '@/models/swap'
import { ERROR_CODE } from '@/models/error-map'

const Auth = namespace('Auth')

@Component({
  components: { StatusInfoPanel, ChatLink, DeliveryInfoPanel, UserAvatar, Icon }
})
export default class Order extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  @Auth.Action
  private updatePoints: (points: number) => void

  private order;
  private orderNumber = '';
  private cancelDisabled = false;
  private isBusy = true;
  private message = '';

  mounted (): void {
    this.getOrder()
  }

  private getOrder (): void {
    if (this.$route.params.id != null) {
      OrderService.getSwapOrder(this.$route.params.id, this.currentUser.id).then(
        (response) => {
          this.order = response.data
        },
        (error) => {
          console.error('something went wrong. Received error ' + error)
          this.message = ERROR_CODE[error.response.data.message]
        }
      ).then(() => {
        this.isBusy = false
        this.orderNumber = this.order.id
      })
    }
  }

  private orderDate (dateString: string): string {
    return DateUtilities.getDateTimeStringFromString(dateString)
  }

  private canBeCanceled (swapStatus: any): boolean {
    return !this.isCanceled(swapStatus) && !this.isCompleted(swapStatus) && !this.isWaitingBuyerConfirmation(swapStatus)
  }

  private isCompleted (swapStatus: any): boolean {
    return swapStatus.name === 'completed'
  }

  private isCanceled (swapStatus: any): boolean {
    return swapStatus.name === 'cancelled'
  }

  private isWaitingBuyerConfirmation (swapStatus: any): boolean {
    return swapStatus.name === 'seller_confirmed'
  }

  private showConfirmationModal (): void {
    // @ts-ignore
    this.$refs['confirmation-modal'].show()
  }

  private hideConfirmationModal (): void {
    // @ts-ignore
    this.$refs['confirmation-modal'].hide()
  }

  private cancelOrder (order: any, statusId: number): void {
    this.isBusy = true
    if (!this.cancelDisabled) {
      this.cancelDisabled = true
      this.hideConfirmationModal()
      this.updateOrder(order, statusId, this.currentUser.id).then(() => {
        if (this.order.toApplicationUserId === this.currentUser.id) {
          const total = this.getTotal(this.order.swaps)
          this.updatePoints(-total)
        }
      })
    }
  }

  private async updateOrder (order: any, statusId: number, userId: number): Promise<void> {
    OrderService.updateOrderStatus(this.order.id, statusId, userId).then(() => {
      this.order.swapStatusId = statusId
      this.order.swapStatus.name = SWAP_STATUS_NAMES[statusId]
    }).then(() => {
      window.location.reload()
    })
  }

  private getTotal (swaps: SwapInOrder[]): number {
    let total = 0
    swaps.forEach(swap => {
      total += swap.toy.price
    })
    return total
  }

  private getToyPhoto (toyPhotos) {
    return PhotoUtilities.getProductPhotoUrl(toyPhotos[0].photoReference)
  }

  get isSeller (): boolean {
    return this.order.fromApplicationUserId === this.currentUser.id
  }

  get userInfo (): any {
    let user
    if (this.isSeller) {
      user = this.order.toApplicationUser
    } else {
      user = this.order.fromApplicationUser
    }
    return {
      userId: user.id,
      pictureReference: user.profilePictureReference,
      firstName: user.firstName,
      lastName: user.lastName
    }
  }
}
