











































































































































import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import OrderService from '@/services/OrderService'
import { namespace } from 'vuex-class'
import UserAvatar from '@/components/UserAvatar.vue'
import ChatLink from '@/components/ChatLink.vue'
import DeliveryInfoPanel from '@/components/DeliveryInfoPanel.vue'
import Order from '@/components/pages/orderPage/Order.vue'
import DateUtilities from '@/utilities/DateUtilities'
import { SwapInOrder } from '@/models/swap'
import { SWAP_STATUS_NAMES } from '@/models/id-maps'

const Auth = namespace('Auth')

@Component({
  components: { Order, UserAvatar, ChatLink, DeliveryInfoPanel }
})
export default class Orders extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  private orders = [];
  private fromCurrentUser = [];
  private toCurrentUser = [];
  private isBusy = true;
  private showOrdersFromUser = true;

  mounted (): void {
    this.fetchUserSwapOrders()
  }

  private fetchUserSwapOrders (): void {
    OrderService.getUserSwapOrders(this.currentUser.id).then(result => {
      result.data.forEach(order => {
        this.orders.push(order)
        if (order.fromApplicationUserId === this.currentUser.id) {
          this.fromCurrentUser.push(order)
        } else {
          this.toCurrentUser.push(order)
        }
      })
      this.sort(this.fromCurrentUser)
      this.sort(this.toCurrentUser)
      this.isBusy = false
    })
  }

  private sort (orders) {
    orders.sort((order1, order2) => {
      return DateUtilities.compareDates(order2.createdAt, order1.createdAt)
    }).sort((order1, order2) => {
      return this.compareStatuses(order1.swapStatusId, order2.swapStatusId)
    })
  }

  private getUserId (order) {
    if (order.fromApplicationUserId === this.currentUser.id) {
      return this.getUserInfo(order.toApplicationUser).userId
    } else {
      return this.getUserInfo(order.fromApplicationUser).userId
    }
  }

  private getUserName (order) {
    let userInfo
    if (order.fromApplicationUserId === this.currentUser.id) {
      userInfo = this.getUserInfo(order.toApplicationUser)
    } else {
      userInfo = this.getUserInfo(order.fromApplicationUser)
    }
    return userInfo.firstName + ' ' + userInfo.lastName
  }

  private getUserInfo (user) {
    return {
      userId: user.id,
      pictureReference: user.profilePictureReference,
      firstName: user.firstName,
      lastName: user.lastName
    }
  }

  private compareStatuses (status1: number, status2: number): number {
    if ([1, 4].includes(status1) && [2, 3].includes(status2)) {
      return -1
    } else if ([2, 3].includes(status1) && [1, 4].includes(status2)) {
      return 1
    } else {
      return 0
    }
  }

  private getTotal (swaps: SwapInOrder[]): number {
    let total = 0
    swaps.forEach(swap => {
      total += swap.toy.price
    })
    return total
  }

  private getSwapStatus (swapStatusId) {
    return SWAP_STATUS_NAMES[swapStatusId]
  }

  private getFormattedDate (order) {
    return DateUtilities.getDateTimeStringFromString(order.updatedAt)
  }

  private isPending (swapStatusId) {
    return swapStatusId === 1
  }

  private isSellerConfirmed (swapStatusId) {
    return swapStatusId === 4
  }

  private isCompleted (swapStatusId) {
    return swapStatusId === 2
  }

  private isCancelled (swapStatusId) {
    return swapStatusId === 3
  }

  private openOrder (orderId: number): void {
    this.$router.push(`/order/${orderId}`)
  }

  private showFrom () {
    this.showOrdersFromUser = true
  }

  private showTo () {
    this.showOrdersFromUser = false
  }
}
