


























import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Navbar from '@/components/navbar/Navbar.vue'
import { namespace } from 'vuex-class'
import AppFooter from '@/components/AppFooter.vue'
import UserService from '@/services/UserService'
import { Notification } from '@/models/notification'
import EnvUtilities from '@/utilities/EnvUtilities'

const AppInfo = namespace('AppInfo')
const Auth = namespace('Auth')
const Chat = namespace('Chat')

@Component({
  components: { AppFooter, Navbar },
  metaInfo (): any {
    return {
      meta: [
        { name: 'description', content: 'Leluelu on keskkond, mis on suunatud mänguasjade vahetamisele registreeritud kasutajate vahel. Mänguasjade vahetus on tasuta ja põhineb punktisüsteemil.' },
        { name: 'keywords', content: 'mänguasjad, kasutatud mänguasjad, vahetus, mänguasjavahetus, soodsad mänguasjad, odavad mänguasjad' },
        { property: 'og:title', content: 'Leluelu - mänguasjavahetus' },
        { property: 'og:site_name', content: 'Leluelu' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: EnvUtilities.getEnv() + '/banner-small.png' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  }
})
export default class App extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  @Auth.Action
  private agreeToTerms!: () => Promise<any>;

  @AppInfo.Action
  private fetchData: () => void;

  @Auth.Action
  private isValidSession: () => boolean;

  @Chat.Action
  private storeNewMessages: (messages) => void;

  @Chat.Action
  private storeNewNotifications: (notifications: Notification[]) => void;

  mounted (): void {
    if (this.$route.query.ref) {
      this.$cookies.set('referral_code', this.$route.query.ref, '1d')
    }
    if (this.isValidSession()) {
      this.fetchData()
      if (this.currentUser) {
        this.getNewChats()
        this.getNewNotifications()
        if (!this.currentUser.agreedToTerms) {
          this.showTermsToast()
        }
      }
    }
  }

  private showTermsToast () {
    this.$bvToast.show('updated-terms-toast')
  }

  consentToTerms (): void {
    this.$bvToast.hide('updated-terms-toast')
    this.agreeToTerms().then(
      () => {
        window.location.reload()
      })
  }

  private getNewChats (): void {
    UserService.getNewMessages(this.currentUser.id).then((response) => {
      this.storeNewMessages(response.data)
    })
  }

  private getNewNotifications (): void {
    UserService.getNewNotifications(this.currentUser.id).then((response) => {
      const notifications: Notification[] = []
      response.data.forEach(not => {
        const notification: Notification = {
          id: not.id,
          content: not.content,
          status: not.status,
          createdAt: not.createdAt
        }
        notifications.push(notification)
      })
      this.storeNewNotifications(notifications)
    })
  }
}
