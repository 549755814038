















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SideNav extends Vue {
  @Prop() navLinks: [];
}

