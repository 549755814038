












import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ERROR_CODE } from '@/models/error-map'

const Auth = namespace('Auth')

@Component
export default class EmailVerification extends Vue {
  private loading = false;
  private message = '';

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private emailVerify!: (data: any) => Promise<any>;

  mounted (): void {
    this.handleNpVerification()
  }

  handleNpVerification (): void {
    if (this.$route.query.email && this.$route.query.token) {
      this.emailVerify({
        token: encodeURIComponent(this.$route.query.token.toString()),
        email: encodeURIComponent(this.$route.query.email.toString())
      }).then(
        () => {
          this.$router.push('/home')
        },
        (error) => {
          this.loading = false
          this.message = ERROR_CODE[error]
        }
      )
    }
  }
}
