






















































































































import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import CartService from '@/services/CartService'
import { Cart, SwapInOrder, SwapOrder } from '@/models/swap'
import { ApplicationUser } from '@/models/application-user'
import { namespace } from 'vuex-class'
import Icon from '@/components/Icon.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import ChatLink from '@/components/ChatLink.vue'
import DeliveryInfoPanel from '@/components/DeliveryInfoPanel.vue'
import BigOrangeButton from '@/components/BigOrangeButton.vue'
import OrderService from '@/services/OrderService'
import { ERROR_CODE } from '@/models/error-map'

const Auth = namespace('Auth')

@Component({
  components: { DeliveryInfoPanel, UserAvatar, Icon, ChatLink, BigOrangeButton }
})
export default class ShoppingCart extends Vue {
  @Auth.State('user')
  private currentUser!: ApplicationUser;

  @Auth.Action
  private updatePoints: (points: number) => void

  private cart: Cart = { orders: [] }
  private errorMessage = '';
  private isBusy = false;

  mounted (): void {
    this.cart = CartService.getCart()
  }

  private reload () {
    window.location.reload()
  }

  private removeFromCart (fromUserId: number, toyId: number): void {
    CartService.removeFromCart(fromUserId, toyId)
    window.location.reload()
  }

  private sendOrder (swapOrder: SwapOrder): void {
    this.isBusy = true
    OrderService.sendOrder(swapOrder).then(() => {
      CartService.removeOrderFromCart(swapOrder.fromApplicationUserId)
      window.location.assign('/orders')
    },
    (error) => {
      if (error.response && error.response.data && error.response.data.message != null) {
        if (error.response.data.message === 'ERR_102') {
          this.errorMessage = 'Kahjuks ei ole teil hetkel piisavalt aktiivseid punkte. Selle tellimuse esitamiseks on teil vaja  ' + swapOrder.total + ' punkti.'
        } else {
          this.errorMessage = ERROR_CODE[error.response.data.message]
          if (error.response.data.message === 'ERR_104') {
            CartService.emptyCart()
          }
        }
      } else {
        this.errorMessage = 'Midagi läks valesti'
      }
      this.$bvModal.show('error-modal')
    }).finally(() => {
      const total = this.getTotal(swapOrder.swaps)
      this.updatePoints(total)
    })
  }

  private getUserInfo (swapOrder: SwapOrder) {
    return {
      pictureReference: swapOrder.fromApplicationUser.profilePictureReference,
      firstName: swapOrder.fromApplicationUser.firstName,
      lastName: swapOrder.fromApplicationUser.lastName
    }
  }

  get ordersExist (): boolean {
    return this.cart !== null && this.cart !== undefined && this.cart.orders !== null &&
      this.cart.orders !== undefined && Object.keys(this.cart.orders).length > 0
  }

  private getTotal (swaps: SwapInOrder[]): number {
    let total = 0
    swaps.forEach(swap => {
      total += swap.toy.price
    })
    return total
  }
}
