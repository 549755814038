

















import { Component, Prop, Vue } from 'vue-property-decorator'
import CategoryTag from '@/components/CategoryTag.vue'
import Icon from '@/components/Icon.vue'
import { namespace } from 'vuex-class'

const AppInfo = namespace('AppInfo')

@Component({
  components: { CategoryTag, Icon }
})
export default class Sorter extends Vue {
  @Prop() heading;
  @Prop() categories;
  @Prop() options;
  @Prop() filterKey;

  @AppInfo.Action
  private clearAllFilters: (param: any) => void;

  @AppInfo.Action
  private updateFilter: () => void;

  isSelected (category: string): boolean {
    return this.options.includes(category)
  }

  closeSecondaryMenu (): void {
    this.$emit('closeSecondaryMenu', true)
  }

  private resetForCategory (): void {
    this.clearAllFilters(this.filterKey)
    this.updateFilter()
  }
}
