




































































































































import { Component, Vue } from 'vue-property-decorator'
import DateUtilities from '@/utilities/DateUtilities'
import { namespace } from 'vuex-class'
import Icon from '@/components/Icon.vue'
import AdminService from '@/services/AdminService'
import { TOY_STATUS_NAMES } from '@/models/id-maps'

const AppInfo = namespace('AppInfo')

@Component({
  components: { Icon }
})
export default class UserManager extends Vue {
  @AppInfo.Getter
  private allUserStatuses: [];

  private sortBy = 'createdAt'
  private sortDesc = true

  private currentPage = 1;
  private allItems = [];
  private itemFields = [
    {
      key: 'id',
      label: 'ID',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'user',
      label: 'kasutaja'
    },
    {
      key: 'email',
      label: 'email',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'emailNotificationsEnabled',
      label: 'emaili teavitused',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'emailConfirmed',
      label: 'email verifitseeritud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'bookedPoints',
      label: 'broneeritud punktid',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'activePoints',
      label: 'aktiivsed punktid',
      sortable: true
    },
    {
      key: 'toysAdded',
      label: 'lelusid lisanud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'swapsCompletedAsSeller',
      label: 'vahetustes loovutaja',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'swapsCompletedAsBuyer',
      label: 'vahetustes soovija',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'swapsCompletedTotal',
      label: 'õnnestunud vahetusi kokku',
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'createdAt',
      label: 'konto loodud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'userStatusId',
      label: 'staatus',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'options',
      label: ''
    }
  ];

  private detailFields = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'user',
      label: 'kasutaja'
    },
    {
      key: 'email',
      label: 'email'
    },
    {
      key: 'emailNotificationsEnabled',
      label: 'emaili teavitused'
    },
    {
      key: 'emailConfirmed',
      label: 'email verifitseeritud'
    },
    {
      key: 'bookedPoints',
      label: 'broneeritud punktid'
    },
    {
      key: 'activePoints',
      label: 'aktiivsed punktid'
    },
    {
      key: 'toysAdded',
      label: 'lelusid lisanud'
    },
    {
      key: 'swapsCompletedAsSeller',
      label: 'vahetustes loovutaja'
    },
    {
      key: 'swapsCompletedAsBuyer',
      label: 'vahetustes soovija'
    },
    {
      key: 'swapsCompletedTotal',
      label: 'õnnestunud vahetusi kokku'
    },
    {
      key: 'createdAt',
      label: 'konto loodud'
    },
    {
      key: 'userStatusId',
      label: 'staatus'
    }
  ];

  mounted (): void {
    this.fetchAllUsers()
  }

  private fetchAllUsers (): void {
    AdminService.fetchAllUsers().then(
      (response) => {
        this.allItems = response.data
      })
  }

  private getDetailFields () {
    return this.detailFields
  }

  private getFormattedDate (timestamp) {
    return DateUtilities.getDateStringFromString(timestamp)
  }

  private updateUser (user) {
    const data = {
      userId: user.id,
      activePoints: Number(user.activePoints),
      userStatusId: user.userStatusId
    }
    AdminService.updateUser(data).then(
      () => {
        window.location.reload()
      })
  }

  private redirectToNotification (userId) {
    window.location.assign('/admin/notifications?userId=' + userId)
  }

  private getUserStatusText (statusId) {
    return TOY_STATUS_NAMES[statusId]
  }
}

