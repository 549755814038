







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ToyPhoto } from '@/models/toy'
import PhotoUtilities from '@/utilities/PhotoUtilities'

@Component
export default class ProductPhotoGallery extends Vue {
  @Prop() toyPhotos;

  private showGalleryModal (): void {
    this.$bvModal.show('gallery')
  }

  private productPhoto (image: ToyPhoto): string {
    return PhotoUtilities.getProductPhotoUrl(image.photoReference)
  }
}
