











































import { Component, Prop, Vue } from 'vue-property-decorator'
import ToyCard from '@/components/ToyCard.vue'
import { namespace } from 'vuex-class'
import { Toy } from '@/models/toy'
const AppInfo = namespace('AppInfo')

@Component({
  components: { ToyCard }
})
export default class ToyGrid extends Vue {
  @Prop() isToysReady: boolean;
  @Prop() toys: Toy[];
  @Prop() itemsOnPage;
  @Prop() currentPage: number;

  get total (): number {
    return this.toys[0] != null ? this.toys[0].count : 0
  }

  search (event, page) {
    this.$emit('searchForPage', page)
  }

  storePageNumber (page) {
    this.$emit('rememberPage', page)
  }
}
