








import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Icon from '@/components/Icon.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import MobileNavbar from '@/components/navbar/MobileNavbar.vue'
import DesktopNavbar from '@/components/navbar/DesktopNavbar.vue'
import CartService from '@/services/CartService'
import UserService from '@/services/UserService'
import { UserPoints } from '@/models/application-user'

const Auth = namespace('Auth')
const Chat = namespace('Chat')

@Component({
  components: { DesktopNavbar, MobileNavbar, Icon, UserAvatar }
})
export default class Navbar extends Vue {
  @Auth.State('user')
  private currentUser

  @Chat.State
  private chatMessages;

  @Chat.State
  private notifications;

  @Auth.Action
  private signOut!: () => void;

  private userInfo: UserPoints = {
    activePoints: undefined,
    bookedPoints: undefined
  }

  mounted (): void {
    if (this.currentUser) {
      this.fetchUser()
    }
  }

  private fetchUser (): void {
    const userId = this.currentUser.id
    if (userId != null) {
      UserService.getUser(userId).then(
        (response) => {
          this.userInfo = response.data
        },
        (error) => {
          this.userInfo =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        })
    }
  }

  get showAdminBoard (): boolean {
    if (this.currentUser && this.currentUser.roles) {
      return this.currentUser.roles.includes('Admin')
    }
    return false
  }

  logOut (): void {
    this.signOut()
    window.location.reload()
  }

  get cartCount (): number {
    return CartService.getItemsCount()
  }
}

