









import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Icon from '@/components/Icon.vue'

const AppInfo = namespace('AppInfo')

@Component({
  components: { Icon }
})
export default class CategoryTag extends Vue {
  @Prop() category;
  @Prop() isSelected;
  @Prop() removeOnly;
  @Prop() size;

  @AppInfo.Action
  private toggleFilterParameter: (param: any) => void;

  @AppInfo.Action
  private updateFilter: () => void;

  private toggleFilterParams (category: any): void {
    this.toggleFilterParameter(category)
    this.updateFilter()
  }
}
